<template>
  <div class="try-out">
    <div class="title">注册使用</div>
    <div class="container">
      <div class="form-item">
        <input v-model.trim="form.name" type="text" placeholder="姓名" @blur="validateName" />
        <div class="error">{{ rules.name }}</div>
      </div>
      <div class="form-item">
        <input v-model.trim="form.phone" type="text" placeholder="手机" @blur="validatePhone" />
        <div class="error">{{ rules.phone }}</div>
      </div>
      <div class="form-item">
        <input v-model.trim="form.email" type="text" placeholder="邮箱" @blur="validateEmail" />
        <div class="error">{{ rules.email }}</div>
      </div>
      <div class="form-item">
        <input v-model.trim="form.company" type="text" placeholder="公司" @blur="validateCompany" />
        <div class="error">{{ rules.company }}</div>
      </div>
      <div class="form-item">
        <textarea v-model.trim="form.comment" placeholder="简单描述您的需求" />
      </div>
      <div class="submit" @click="toSubmit">
        <span>提交申请</span>
      </div>
    </div>

    <div class="message" v-if="message">
      <div>您已经预约成功</div>
    </div>
  </div>
</template>

<script>
// import { tryout } from '../../api/report'
import qs from 'qs'
import axios from 'axios'

export default {
  data() {
    return {
      form: { name: '', phone: '', email: '', company: '', comment: '' },
      rules: {
        name: '',
        phone: '',
        email: '',
        company: '',
      },
      message: false,
      timer: null,
    }
  },
  methods: {
    validateName() {
      if (!this.form.name) {
        this.rules.name = '请输入姓名'
        return false
      } else {
        this.rules.name = ''
        return true
      }
    },
    validateEmail() {
      let pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (!this.form.email) {
        this.rules.email = '请输入邮箱'
        return false
      } else if (!pattern.test(this.form.email)) {
        this.rules.email = '邮箱格式不正确'
        return false
      } else {
        this.rules.email = ''
        return true
      }
    },
    validatePhone() {
      let pattern = /(^(13|14|15|16|17|18|19)[0-9]{9}$)/
      if (!this.form.phone) {
        this.rules.phone = '请输入手机号码'
        return false
      } else if (!pattern.test(this.form.phone)) {
        this.rules.phone = '手机号格式不正确'
        return false
      } else {
        this.rules.phone = ''
        return true
      }
    },
    validateCompany() {
      if (!this.form.company) {
        this.rules.company = '请输入公司名称'
        return false
      } else {
        this.rules.company = ''
        return true
      }
    },
    validate() {
      let flag = []
      flag[0] = this.validateName()
      flag[1] = this.validatePhone()
      flag[2] = this.validateEmail()
      flag[3] = this.validateCompany()

      return !flag.includes(false)
    },
    showMessage() {
      this.message = true
      this.timer = setTimeout(() => {
        this.$router.push({ path: '/' })
      }, 3000)
    },
    toSubmit() {
      if (this.validate()) {
        let form = qs.stringify({
          ...this.form,
        })
        axios({
          // url: '/znzp/userApply/addCustomerApply.shtml',
          url: `${process.env.VUE_APP_API_GLOBAL}/candidate/chatboot/interview/addCustomerApply`,
          method: 'post',
          data: form,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }).then(() => {
          this.showMessage()
        })
      }
    },
  },
  destroyed() {
    clearTimeout(this.timer)
  },
}
</script>

<style lang="scss" scoped>
.try-out {
  height: 100%;
  padding-top: 30px;
  .title {
    text-align: center;
    color: #0a62eb;
    font-size: 20px;
  }

  .container {
    width: 300px;
    margin: 0 auto;
    padding-top: 20px;
    .form-item {
      margin: 10px 0 10px 0;

      input,
      textarea {
        padding: 0 15px;
        width: 270px;
        background: #f7f7f7;
        border: none;
        font-size: 15px;
        border-radius: 5px;
      }
      input {
        height: 40px;
        line-height: 40px;
      }
      textarea {
        height: 80px;
        padding: 15px;
        resize: none;
      }
      .error {
        color: red;
        height: 20px;
        line-height: 20px;
        margin-top: 5px;
      }
    }
    .submit {
      margin-top: 30px;
      background: #035deb;
      border-radius: 10px;
      color: #ffffff;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 16px;
      cursor: pointer;
    }
  }
  .message {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    div {
      height: 60px;
      padding: 0 30px;
      line-height: 60px;
      font-size: 20px;
      background: #ffffff;
      color: #67c23a;
    }
  }
}
</style>
